import Typography from '@mui/material/Typography'
import { OrganizationUrlResource } from '@rallycry/api-suite-typescript/dist/models/OrganizationUrlResource'
import { CardSquare } from '../card/CardSquare'
import { RcIcon } from '@/components/atoms/RcIcon'

export interface SponsorCardProps {
  sponsor: OrganizationUrlResource
}

export const SponsorCard: React.FC<SponsorCardProps> = ({ sponsor }) => {
  return (
    <CardSquare
      additionalHoverContent={
        <RcIcon mt={3} icon={['fal', 'link']} size='lg' />
      }
      CardMediaProps={{
        alt: sponsor?.name,
        image: sponsor?.image,
        sx: {
          backgroundSize: 'contain'
        }
      }}
      LinkProps={{
        href: sponsor?.url
      }}
      sx={{ height: '100%' }}
    >
      <Typography variant='body0' align='center'>
        {sponsor?.name ? sponsor.name : 'Local Sponsor'}
      </Typography>
    </CardSquare>
  )
}
