import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ButtonProps } from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import React, { forwardRef, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { ConfirmationDialog } from './ConfirmationDialog'
import { RcButton } from './RcButton'
import { RcIconButton } from './RcIconButton'
import {
  ModalContentProps,
  ModalTrigger
} from '@/components/organisms/modal/ModalTrigger'
import { ModalCardProps } from '@/components/organisms/modal/ModalCard'

type ModalContent = (props: ModalContentProps) => React.ReactNode
export interface ActionMenuOption {
  buttonName?: string | React.ReactNode
  selected?: boolean
  disabled?: boolean
  key: string | number
  icon?: React.ReactNode
  display: React.ReactNode
  shortcut?: React.ReactNode
  confirmation?: React.ReactNode
  confirmMessage?: string
  confirmToken?: string
  action?: () => Promise<any>
  modal?: ModalContent
  modalProps?: Partial<ModalCardProps>
  divider?: boolean
  skipClose?: boolean
}

export const ActionMenu = forwardRef(function Am(
  {
    value,
    endicon,
    onOpen,
    onClose,
    options,
    icon,
    buttonProps,
    children
  }: {
    value?: any
    endicon?: React.ReactNode
    onOpen?: () => void
    onClose?: () => void
    options: ActionMenuOption[]
    icon?: IconProp
    padding?: number
    buttonProps?: ButtonProps
    children?: React.ReactNode
  },
  ref: any
) {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [pending, setPending] = useState<ActionMenuOption | null>(null)
  const buttonSize = 'medium'

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setMenuAnchor(event.currentTarget)
    onOpen?.()
  }

  const closeMenu = () => {
    setMenuAnchor(null)
    onClose?.()
  }

  // since we use disableScrollLock to keep the page from janking when menus open,
  // we need to close them when the user scrolls or else they float away from parent
  useEffect(() => {
    const handle = () => setMenuAnchor(null)
    const curr = window.document
    curr?.addEventListener('scroll', handle, false)
    return () => {
      curr?.removeEventListener('scroll', handle, false)
    }
  }, [])

  const onAction = async (option: ActionMenuOption) => {
    !option.skipClose && closeMenu()
    if (option.confirmation) {
      setShowConfirmation(true)
      setPending(option)
    } else if (option.modal) {
      setPending(option)
      setShowConfirmation(true)
    } else {
      await option.action?.()
    }
  }

  const handleCancel = () => {
    setShowConfirmation(false)
    setPending(null)
  }

  const handleOk = async () => {
    setShowConfirmation(false)

    if (pending) {
      await pending.action?.()
    }

    setPending(null)
  }

  return (
    <Box width={buttonProps?.fullWidth ? '100%' : undefined}>
      {endicon ? (
        <Tab
          ref={ref}
          value={value}
          onClick={openMenu}
          label={
            <Stack direction='row' spacing={2}>
              {children}
              {endicon}
            </Stack>
          }
        />
      ) : children ? (
        <RcButton
          size={buttonSize}
          ref={ref}
          variant={'text'}
          onClick={openMenu}
          endIcon={endicon}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          disabled={!onOpen && options.length === 0}
          sx={{
            borderRadius: 0,
            p: 0,
            minWidth: 0
          }}
          {...buttonProps}
        >
          {children}
        </RcButton>
      ) : (
        <RcIconButton
          size={buttonProps?.size || buttonSize}
          ref={ref}
          onClick={openMenu}
          disabled={!onOpen && options.length === 0}
          icon={icon ? icon : ['fal', 'ellipsis-h']}
          sx={buttonProps?.sx}
        />
      )}

      <Menu
        id='profile-menu'
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={closeMenu}
        TransitionComponent={Fade}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <ActionMenuItems options={options} onAction={onAction} />
        {!options || options.length === 0 ? (
          <MenuItem disabled>
            <Skeleton width={90} />
          </MenuItem>
        ) : null}
      </Menu>

      <ConfirmationDialog
        id={pending?.key.toString() || ''}
        message={pending?.confirmation || ''}
        confirmMessage={pending?.confirmMessage}
        confirmToken={pending?.confirmToken}
        ok={pending?.buttonName}
        open={showConfirmation && !!pending?.confirmation}
        onCancel={handleCancel}
        onOk={handleOk}
      />

      <ModalTrigger
        open={showConfirmation && !!pending?.modal}
        onClose={() => setShowConfirmation(false)}
        activation={() => <></>}
        modalProps={pending?.modalProps}
      >
        {modalProps =>
          pending?.modal?.({
            handleClose: () => {
              setPending(null)
              modalProps.handleClose()
            }
          })
        }
      </ModalTrigger>
    </Box>
  )
})

export const ActionMenuItems = ({
  options,
  onAction
}: {
  options: ActionMenuOption[]
  onAction: (option: ActionMenuOption) => void
}) => {
  return options.map(o => [
    <MenuItem
      id={o.key.toString()}
      key={o.key}
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        onAction(o)
      }}
      selected={o.selected}
      disabled={o.disabled}
      sx={{ whiteSpace: 'normal', maxWidth: 300 }}
    >
      {o.icon ? (
        <ListItemIcon
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pr: 3
          }}
        >
          {o.icon}
        </ListItemIcon>
      ) : null}
      {o.display}
      <Typography
        variant='subtitle2'
        color='text.secondary'
        textAlign='right'
        fontSize={'.6rem'}
        pl={5}
        flexGrow={1}
      >
        {o.shortcut}
      </Typography>
    </MenuItem>,
    o.divider ? <Divider key={o.key + 'divider'} /> : null
  ])
}
