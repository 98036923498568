import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { TwitterTimelineEmbed } from '@/components/organisms/site/TwitterEmbed'
import { PageItem } from '@/components/organisms/page/PageItem'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useOrganizationUrls } from '@/entity/organization/useOrganizationUrls'

export const OrganizationTweets = () => {
  const { urls } = useOrganizationUrls()
  const { featTwitterFeeds } = useFeatures()

  const twitter = urls?.find(
    it =>
      it.network === NetworkKind.TWITTER ||
      it.url?.toLocaleLowerCase?.()?.includes('twitter.com') ||
      it.url?.toLocaleLowerCase?.()?.includes('x.com')
  )

  if (!twitter?.url || !featTwitterFeeds) return null

  return (
    <PageItem contained={false} overflow='hidden'>
      <TwitterTimelineEmbed url={twitter.url} />
    </PageItem>
  )
}
