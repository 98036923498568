import Box from '@mui/material/Box'
import { PropsWithChildren } from 'react'

interface PreventParentClickProps extends PropsWithChildren {
  inline?: boolean
}

export const PreventParentClick = ({
  children,
  inline = false
}: PreventParentClickProps) => {
  return (
    <Box
      onClick={ev => {
        ev.stopPropagation()
      }}
      sx={{ display: inline ? 'inline-flex' : 'block' }}
    >
      {children}
    </Box>
  )
}
