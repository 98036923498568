import { useLayoutEffect } from 'react'

export const useScrollToError = (errors: any, errorEleClass: string) => {
  const len = Object.keys(errors)?.length
  useLayoutEffect(() => {
    if (len) {
      const container = document.querySelector(`.${errorEleClass}`)
      container?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [len, errorEleClass])
}
