import {
  CoreDocumentControllerApi,
  SearchCoresRequest
} from '@rallycry/api-suite-typescript/dist/apis/CoreDocumentControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useCoreDocument = (options: EntityOptions<SearchCoresRequest>) => {
  const { ctrl } = useController(CoreDocumentControllerApi)

  const entity = useQueryEntity({
    key: 'useCoreDocument',
    ...options,
    request: { ...options?.request },
    read: req =>
      ctrl({
        expectedStatuses: [403],
        fallbackResponse: { content: [] }
      }).searchCores(req)
  })

  return { ...entity, cores: entity.flat }
}
