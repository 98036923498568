import { TwitchAlert } from './TwitchAlert'
import { useTwitchOnline } from '@/entity/site/useTwitchOnline'

export const TwitchAlerts = ({ identifiers }: { identifiers: string[] }) => {
  const result = useTwitchOnline({
    request: { identifiers }
  })

  return (
    <>
      {identifiers?.map(it => {
        const value = result?.[it]
        const isOnline = !!value?.stream?.id
        if (!isOnline) return null
        return <TwitchAlert key={it} identifier={it} />
      })}
    </>
  )
}
