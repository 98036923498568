import { DocumentSearch } from '../../CMS/DocumentSearch'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { RootRoute } from '@/core/route-keys'

export interface OrganizationResourcesProps {
  direction?: 'column' | 'row' | undefined
  limit?: number
  omit?: string
  slideCards?: boolean
  onTotalResults?: (total: number) => void
}

export const OrganizationResources = ({
  direction,
  limit,
  omit,
  slideCards,
  onTotalResults
}: OrganizationResourcesProps) => {
  const { featElasticResources, cfgPinnedHomeResources } = useFeatures()
  const { cfgPartnerResource } = useFeatures()

  const engine = cfgPartnerResource?.toString()
  const pinned = JSON.parse(cfgPinnedHomeResources as string) as string[]

  return (
    <DocumentSearch
      direction={direction}
      limit={limit}
      omit={omit}
      engine={engine}
      rootRoute={RootRoute.Resources}
      ids={pinned}
      slideCards={slideCards}
      onTotalResults={onTotalResults}
    />
  )
}
