import Typography from '@mui/material/Typography'

export const CharacterCount = ({
  value,
  max
}: {
  value: string
  max: number
}) => {
  const count = value?.length || 0
  const remaining = max - count
  const color = remaining < 0 ? 'error' : 'textSecondary'

  return (
    <Typography variant='body2' color={color} align='right' pt={1}>
      {count} / {max}
    </Typography>
  )
}
