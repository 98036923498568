import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia, { CardMediaProps } from '@mui/material/CardMedia'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import { CardProps } from '@mui/material/Card'
import React, { PropsWithChildren, useState } from 'react'
import {
  NavigationLink,
  NavigationLinkProps
} from '../navigation/NavigationLink'
import {
  ImageDisplay,
  ImageDisplayProps
} from '@/components/atoms/ImageDisplay'

interface CardSquareProps extends CardProps {
  additionalHoverContent?: React.ReactNode
  CardMediaProps: CardMediaProps & ImageDisplayProps
  LinkProps: NavigationLinkProps
}

export const CardSquare = ({
  additionalHoverContent,
  children,
  CardMediaProps,
  LinkProps,
  ...RestCardProps
}: PropsWithChildren<CardSquareProps>) => {
  const [hoverState, setHoverState] = useState(false)
  return (
    <Card
      elevation={3}
      onMouseEnter={() => {
        setHoverState(true)
      }}
      onMouseLeave={() => {
        setHoverState(false)
      }}
      {...RestCardProps}
    >
      <NavigationLink
        {...LinkProps}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        }}
      >
        <Box height={0} paddingTop={'56.25%'} position='relative'>
          <CardMedia
            component={ImageDisplay}
            height='100%'
            width='100%'
            top={0}
            position='absolute'
            transparent
            {...CardMediaProps}
          />
        </Box>
        <CardContent
          sx={theme => ({
            backgroundColor: 'secondary.main',
            color: theme.palette.secondary.contrastText,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            pt: 4
          })}
        >
          {children}
          <Slide in={hoverState} direction='up'>
            <Stack
              spacing={2}
              sx={{
                backgroundColor: 'secondary.main',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                p: 4
              }}
            >
              {children}
              {additionalHoverContent}
            </Stack>
          </Slide>
        </CardContent>
      </NavigationLink>
    </Card>
  )
}
