'use client'
import { some } from 'lodash-es'
import { useOrganizationUrls } from '@/entity/organization/useOrganizationUrls'
import { Sponsors } from '@/components/organisms/site/Sponsors'

export const OrganizationSponsors = () => {
  const { urls } = useOrganizationUrls()

  const content = urls.filter(it => it.options === 'sponsor')

  if (!some(content)) return null

  return <Sponsors urls={content!} />
}
