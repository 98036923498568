'use client'

import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { MyFeed } from './components/MyFeed'
import { OrganizationResources } from './components/OrganizationResources'
import { OrganizationTweets } from './components/OrganizationTweets'
import { Tutorial } from './components/Tutorial'
import { OrganizationSponsors } from './OrganizationSponsors'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RootRoute } from '@/core/route-keys'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { RecentNews } from '@/components/organisms/news/RecentNews'
import { OrgHeader } from '@/components/organisms/site/OrgHeader'
import { TwitchAlerts } from '@/components/organisms/twitch/TwitchAlerts'
import { Page } from '@/components/organisms/page/Page'
import { PageItem } from '@/components/organisms/page/PageItem'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useNavigation } from '@/core/hooks/useNavigation'

export const Home = () => {
  const { featElasticResources, cfgTwitchChannels } = useFeatures()
  const [showResources, setShowResources] = useState(featElasticResources)
  const [showBlogs, setShowBlogs] = useState(true)
  const { getPath } = useNavigation()

  return (
    <Page>
      <OrgHeader />

      <TwitchAlerts identifiers={(cfgTwitchChannels as string)?.split(',')} />

      <Tutorial />

      <RcSuspense skipError skipLoader>
        <MyFeed />
      </RcSuspense>
      {showBlogs ? (
        <PageItem contained={false}>
          <PageItemContent pb={0}>
            <SectionHeader
              title={
                <NavigationLink
                  to={getPath({ root: RootRoute.News })}
                  style={{ color: 'inherit' }}
                  underline='none'
                >
                  <RcTrans i18nKey='home:info.news' />
                </NavigationLink>
              }
            >
              <Typography align='right'>
                <NavigationLink
                  to={getPath({
                    root: RootRoute.Blogs
                  })}
                >
                  <RcButton variant='text' size='small'>
                    <RcTrans i18nKey='shared.view-more' />
                  </RcButton>
                </NavigationLink>
              </Typography>
            </SectionHeader>
          </PageItemContent>
          <PageItemContent px={{ xs: 0, sm: 4 }}>
            <RecentNews
              limit={6}
              slideCards
              onTotalResults={total => setShowBlogs(total > 0)}
            />
          </PageItemContent>
        </PageItem>
      ) : null}
      {showResources ? (
        <PageItem contained={false}>
          <PageItemContent pb={0}>
            <SectionHeader
              title={
                <NavigationLink
                  to={getPath({ root: RootRoute.Resources })}
                  style={{ color: 'inherit' }}
                >
                  <RcTrans i18nKey={'home:info.resources'} />
                </NavigationLink>
              }
            >
              <Typography align='right'>
                <NavigationLink
                  to={getPath({
                    root: RootRoute.Resources
                  })}
                >
                  <RcButton variant='text' size='small'>
                    <RcTrans i18nKey='shared.view-more' />
                  </RcButton>
                </NavigationLink>
              </Typography>
            </SectionHeader>
          </PageItemContent>
          <PageItemContent px={{ xs: 0, sm: 4 }}>
            <OrganizationResources
              slideCards
              onTotalResults={total => setShowResources(total > 0)}
            />
          </PageItemContent>
        </PageItem>
      ) : null}

      <RcSuspense skipError skipLoader>
        <OrganizationSponsors />
      </RcSuspense>

      <OrganizationTweets />
    </Page>
  )
}
