'use client'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import { FieldAttributes } from 'formik'
import { TextField } from 'formik-mui'
import React, { useCallback, useEffect } from 'react'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

type StatusTextFieldProps = FieldAttributes<any> & {
  startIcon?: IconProp
  value: string
  required: boolean
  dirty: boolean
  saving: boolean
  error: boolean
  inputPropBlur: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void | null
  onCancel: () => void
  autoFocus?: boolean
  placeholder?: React.ReactNode
}

export const StatusTextField: React.FC<StatusTextFieldProps> = ({
  required,
  dirty,
  disabled,
  inputPropBlur,
  onCancel,
  startIcon,
  value,
  saving,
  error,
  placeholder,
  ...props
}) => {
  const { t } = useRcTranslation()
  let statusIcon: IconProp
  let color: any
  let pulse = false
  let tooltip = ''

  if (!value) {
    statusIcon = required ? ['fal', 'asterisk'] : ['fal', 'pencil-alt']
    color = required ? 'warning' : undefined
    tooltip = required ? t('shared.required') : t('shared.optional')
  } else if (error) {
    statusIcon = ['fal', 'times']
    color = 'warning'
    tooltip = t('shared.error')
  } else if (saving) {
    statusIcon = ['fal', 'spinner']
    tooltip = t('shared.saving')
    pulse = true
  } else if (dirty) {
    statusIcon = ['fal', 'tilde']
    tooltip = t('shared.editing')
  } else {
    statusIcon = ['fal', 'check']
    tooltip = t('shared.saved')
  }

  const onBlur = async (
    evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (dirty) {
      await inputPropBlur(evt)
    } else {
      onCancel?.()
    }
  }

  const handleKeyEvent = useCallback(
    async (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        onCancel?.()
      }
    },
    [onCancel]
  )

  useEffect(() => {
    document.addEventListener('keyup', handleKeyEvent, false)
    return () => document.removeEventListener('keyup', handleKeyEvent, false)
  }, [handleKeyEvent])

  return (
    <LabeledField
      component={TextField}
      {...props}
      disabled={disabled || saving}
      InputProps={{
        ...props.InputProps,
        placeholder: placeholder,
        onKeyPress: (e: any) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            e.target.blur()
          }
        },
        onBlur: onBlur,
        startAdornment: startIcon ? (
          <InputAdornment position='start'>
            <RcIcon icon={startIcon} size='sm' />
          </InputAdornment>
        ) : null,
        endAdornment: disabled ? (
          <Tooltip title={t('shared.locked-field')} placement='left' arrow>
            <InputAdornment position='end'>
              <RcIcon icon={['fal', 'lock-alt']} size='xs' />
            </InputAdornment>
          </Tooltip>
        ) : (
          <Tooltip title={tooltip} placement='left' arrow>
            <InputAdornment position='end'>
              <RcIcon color={color} pulse={pulse} icon={statusIcon} size='xs' />
            </InputAdornment>
          </Tooltip>
        )
      }}
    />
  )
}
