import Grid from '@mui/material/Grid'
import { FormikHelpers } from 'formik'
import { partition, some } from 'lodash-es'
import React, { useCallback } from 'react'
import { usePage } from '@/components/providers/site/PageProvider'
import { ErrorCode } from '@/core/error-code'
import { tryExtractJsonError } from '@/core/utils'

export interface ErrorResult {
  code: ErrorCode
  field: string
  message: string
  context?: any
  rejectedValue?: string
  acceptWith?: string
}

/*
 * Allows client to handle an API error.  Will attempt to map error to existing form field, or show in snackbar if no helpers included.
 */
export const useApiError = () => {
  const { open, appendToAccepted, resetAccepted } = usePage()

  const handle = async (
    error: any,
    options?: {
      helpers?: FormikHelpers<any>
      field?: string
      values?: Record<string, any>
      excludedCodes?: string[]
    }
  ) => {
    const { helpers, field, values, excludedCodes } = options || {}

    try {
      const skip = [401, 403]
      if (skip.includes((error as any)?.status)) return

      const err = await tryExtractJsonError(error)

      const results: ErrorResult[] =
        err?.issues?.map(it => ({
          ...it,
          field: field || it.field
        })) || []

      if ((error as any).status === 404 && field) {
        results.push({
          code: ErrorCode.NotFound,
          field: field,
          message: `${field} resource not found`
        })
      }

      // when helpers do not exist, treat all as "withoutField"
      // otherwise, split into errors that can be associated with a field
      // and those that should be shown outside of the form in snackbar
      // skip handling any excludedCodes
      const fields = Object.keys(values || {})

      // append to accepted if any results have acceptWith
      results.forEach(it => it.acceptWith && appendToAccepted(it.acceptWith))
      // if no results have acceptWith, clear accepted to handle edge case
      // where first error was acceptable but then subsequent were not
      if (!results.some(it => it.acceptWith)) resetAccepted()

      const [withField, withoutField] = partition(
        results.filter(
          it => !excludedCodes || !excludedCodes?.includes(it.code)
        ),
        it => !!helpers && fields.includes(it.field)
      )

      withField?.forEach(it => helpers?.setFieldError(it.field, it.message))

      if (some(withoutField)) {
        const issues = (
          <Grid container direction='column'>
            {withoutField.map((it: any) => (
              <Grid key={it.field} item style={{ wordBreak: 'break-all' }}>
                {it.rejectedValue ? `[${it.rejectedValue}] ` : ''}
                {it.message} (${it.code})
              </Grid>
            ))}
          </Grid>
        )
        open(null, issues, 8000)
      }
      return results
    } catch (e) {
      console.log(e)
      return [] as ErrorResult[]
    }
  }

  return { handle }
}
