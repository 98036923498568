'use client'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo } from 'react'
import { isDarkMode } from '../utils'

// actually check device agent
export const useIsMobileDevice = () =>
  useMemo(() => {
    return (
      /Android|webOS|iPhone|iPad|iPod|Opera Mini/.test(navigator.userAgent) &&
      !(window as any).MSStream
    )
  }, [])

export const useIsXs = () => {
  return useMediaQuery('@media (max-width:725px)', {
    defaultMatches: true
  })
}

export const useIsMobile = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  })
}

export const useIsTablet = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  })
}

export const useIsSmUp = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true
  })
}

export const useIsSm = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.between('xs', 'sm'), {
    defaultMatches: true
  })
}

export const useIsMdUp = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  })
}

export const useIsLgUp = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })
}

export const useIsLgDown = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('lg'), {
    defaultMatches: true
  })
}

export const useIsDarkMode = () => {
  const theme = useTheme()
  return isDarkMode(theme)
}
