'use client'
import { PropsWithChildren, useEffect } from 'react'
import { RootRoute } from '@/core/route-keys'
import { useOrganization } from '@/entity/organization/useOrganization'
import { Step, useGateway } from '@/entity/site/useGateway'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

export const Protected = (
  props: PropsWithChildren<{
    // requires a user to be logged in.
    loggedIn?: boolean
    // route requires a user to be an org moderator. logged out users disallowed.
    orgModerator?: boolean
    // logged in users allowed only if cta is complete. logged out users unaffected.
    ctaComplete?: boolean
    // route is allowed during limitedUnathed mode
    allowLimited?: boolean
  }>
) => {
  const { user } = useFirebase()
  const { isOrgModerator } = useOrganization()
  const { featLimitedUnauthed } = useFeatures()
  const { replaceTo } = useNavigation()
  const cta = useGateway()
  const ctaComplete = cta.step && cta.step === Step.Complete

  // logged in user required
  useEffect(() => {
    if (props.loggedIn && !user) {
      replaceTo({ root: RootRoute.Login })
    }
  }, [props.loggedIn, user, replaceTo])

  // featLimitedUnathed feature enabled and user is not logged in
  // some orgs want to show blogs and other static content,
  // but not communities & competitions or other dynamic data
  useEffect(() => {
    if (!props.allowLimited && featLimitedUnauthed && !user) {
      replaceTo({ root: RootRoute.Login })
    }
  }, [props.allowLimited, featLimitedUnauthed, user, replaceTo])

  // cta complete required
  useEffect(() => {
    if (props.ctaComplete && user && !ctaComplete) {
      replaceTo({ root: RootRoute.Registration })
    }
  }, [props.ctaComplete, user, ctaComplete, replaceTo])

  if (props.loggedIn && !user) {
    return null
  }

  if (!props.allowLimited && featLimitedUnauthed && !user) {
    return null
  }

  if (props.orgModerator && !isOrgModerator) {
    return null
  }

  if (props.ctaComplete && !ctaComplete) {
    return null
  }

  return props.children
}
