import { PageHeader } from '../page/PageHeader'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useFeatures } from '@/components/providers/site/FeatureProvider'

export const OrgHeader = ({ children }: { children?: React.ReactNode }) => {
  const { organization } = useOrganization()
  const { imgPageBanner } = useFeatures()

  return (
    <PageHeader
      centered
      AvatarProps={{
        src: organization?.image
      }}
      ImageDisplayProps={{ path: imgPageBanner as string }}
      name={organization?.name}
    >
      {children}
    </PageHeader>
  )
}
