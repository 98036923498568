'use client'

import React, { useEffect } from 'react'
import { Redirect } from '../CMS/dynamic/Redirect'
import { Home } from '../Home/_Home'
import { DocumentPage } from '../CMS/DocumentPage'
import { usePage } from '@/components/providers/site/PageProvider'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { StrapiResult } from '@/entity/site/useSearchDocument'

// send user to their last visited route (if any) or render Home route (for root behavior)
export const HomeOrRedirectToPreviousPath = ({
  landing
}: {
  landing?: StrapiResult
}) => {
  const { user } = useFirebase()
  const { redirect, setRedirect } = usePage()
  const { featCMSOnly } = useFeatures()

  useEffect(() => setRedirect(''), [setRedirect])

  return !user ? (
    <DocumentPage doc={landing} skipHeader withItems unauthFullWidth />
  ) : redirect ? (
    <Redirect replace path={`${redirect}`} />
  ) : featCMSOnly ? (
    <DocumentPage doc={landing} skipHeader withItems unauthFullWidth />
  ) : (
    <Home />
  )
}
