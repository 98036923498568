import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'

export interface RcIconProps extends FontAwesomeIconProps {
  cursor?: string
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'error'
    | 'success'
  backgroundColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'error'
    | 'success'
  colorHex?: string
  m?: string | number
  mb?: string | number
  ml?: string | number
  mr?: string | number
  mt?: string | number
  my?: string | number
  rotate?: number
  inline?: boolean
  zoom?: number
}

/*
 * Wrapper for Font Awesome Icons with formatting options
 */
export const RcIcon = ({
  color,
  backgroundColor,
  cursor,
  colorHex,
  m,
  mb,
  ml,
  mr,
  mt,
  my,
  inline,
  size,
  rotate,
  zoom,
  ...rest
}: RcIconProps) => {
  const constrained = size && ['xs', 'sm', 'lg', '1x'].includes(size)

  return (
    <Box
      m={m}
      mb={mb}
      ml={ml}
      mr={mr}
      mt={mt}
      my={my}
      width={constrained && !inline ? 20 : undefined}
      height={constrained && !inline ? 20 : undefined}
      display={inline ? 'inline-flex' : 'flex'}
      alignItems='center'
      alignContent='center'
      justifyContent='center'
      sx={theme => ({
        zoom,
        cursor,
        color: color
          ? theme.palette[color].main
          : colorHex
          ? colorHex
          : undefined,
        backgroundColor: backgroundColor
          ? theme.palette[backgroundColor].main
          : undefined,
        borderRadius: 100,
        transform: rotate ? `rotate(${rotate || 0}deg)` : undefined,
        transition: rotate
          ? theme =>
              theme.transitions.create('all', {
                duration: theme.transitions.duration.shortest
              })
          : undefined
      })}
    >
      <FontAwesomeIcon size={size} {...rest} />
    </Box>
  )
}
