'use client'

import { StringMap, TOptions } from 'i18next'
import { set } from 'lodash-es'
import { PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import { useOrganization } from '../../entity/organization/useOrganization'
import { RcInlineEdit } from '@/components/molecules/input/RcInlineEdit'
import { RcTextInput } from '@/components/molecules/input/RcTextInput'
import { getNamespacedKey, TranslationNamespace } from '@/core/translation'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { usePage } from '@/components/providers/site/PageProvider'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'

export interface RcTransProps {
  viewOnly?: boolean
  i18nKey: string
  tOptions?: TOptions<StringMap>
  multiline?: boolean
}

// This can be drop-in replaced for most usages of t() or <Trans></Trans>
export const RcTrans = ({
  children,
  viewOnly,
  i18nKey,
  tOptions = {},
  multiline = true
}: PropsWithChildren<RcTransProps>) => {
  const { showKeys } = usePage()
  const { isOrgSuperAdmin } = useOrganization()
  const { featAdminInlineEdit } = useFeatures()

  const namespacedKey = getNamespacedKey(i18nKey)
  const [namespace] = namespacedKey.split(':')
  const { t, i18n, updateLocaleOverride } = useRcTranslation([
    namespace as TranslationNamespace
  ])

  const active = isOrgSuperAdmin && featAdminInlineEdit && !viewOnly

  const options = { ...tOptions }

  const display = children ? (
    <Trans
      t={t}
      i18nKey={i18nKey}
      tOptions={options}
      style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}
    >
      {children}
    </Trans>
  ) : (
    <>{t(i18nKey, options)}</>
  )

  if (!active) {
    return <>{display}</>
  }

  if (showKeys) {
    return (
      <Link
        href={`https://github.com/rallycry/client-web/blob/develop/public/locales/${i18n.language}/${namespace}.json`}
        style={{
          textTransform: 'lowercase',
          textDecoration: 'none',
          color: 'inherit'
        }}
        target='_new'
        rel='noopener'
      >
        {i18nKey}
      </Link>
    )
  }

  const getTokenizedValue = () => {
    const keys = Object.keys(options)
    const result = {}

    // have to swap out the {{}}'s for [[]]'s temporarily to get past normal replacement
    for (const key of keys) {
      set(result, key, `[[${key}]]`)
    }

    return t(i18nKey, result).replace(/\[\[/g, '{{').replace(/\]\]/g, '}}')
  }

  return (
    <RcInlineEdit
      canEdit
      showUnset
      ctrlRequired
      Component={RcTextInput}
      ComponentProps={{
        property: 'value',
        source: { value: getTokenizedValue() },
        update: (res: any) => updateLocaleOverride(i18nKey, res.value),
        startIcon: ['fal', 'clone'],
        defaultValue: '',
        autoFocus: true,
        multiline: multiline
      }}
    >
      {display}
    </RcInlineEdit>
  )
}
