import Grid from '@mui/material/Grid'
import { OrganizationUrlResource } from '@rallycry/api-suite-typescript/dist/models/OrganizationUrlResource'
import { some } from 'lodash-es'
import { PageItem } from '../page/PageItem'
import { SponsorCard } from './SponsorCard'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

export const Sponsors = ({ urls }: { urls: OrganizationUrlResource[] }) => {
  const { t } = useRcTranslation()

  const localSponsors = urls.filter(it => it.options === 'sponsor')

  return (
    <>
      {some(localSponsors) ? (
        <PageItem>
          <SectionHeader title={t('shared.local-sponsors-title')} />
          <Grid
            container
            spacing={4}
            display='flex'
            flexDirection='row'
            alignItems='stretch'
            mt={1}
          >
            {localSponsors.map(it => (
              <Grid key={it.id} item xs={6} sm={3}>
                <SponsorCard sponsor={it} />
              </Grid>
            ))}
          </Grid>
        </PageItem>
      ) : null}
    </>
  )
}
